import * as Sentry from "@sentry/browser";
Sentry.init({
    dsn: "https://9c6a05f2faeece2cb461ff8610bebaff@sent.ixbt.site/20",
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    integrations: [
        Sentry.browserTracingIntegration(),

    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 0.1,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost",
        /^https:\/\/www\.ixbt\.com/,
        /^https:\/\/ixbt\.games/,
        /^https:\/\/www1\.ru/,
        /^https:\/\/ixbt\.pro/,
        /^https:\/\/one\.test/
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    //replaysSessionSampleRate: 0.1,
    //replaysOnErrorSampleRate: 1.0,
});